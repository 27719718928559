<template>
  <div>
    <!-- Header -->
    <div class="relative bg-gray-900 h-screen">
      <!-- Decorative image and overlay -->
      <div aria-hidden="true" class="absolute inset-0 overflow-hidden">
        <img
          :src="lunch.Header_Image"
          alt=""
          class="w-full h-full object-center object-cover"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        />
        <div aria-hidden="true" class="absolute inset-0 bg-black opacity-60" />
      </div>

      <div
        class="relative max-w-3xl mx-auto py-40 px-6 flex flex-col items-center text-center sm:py-30 sm:py-40 lg:px-0"
      >
        <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <img class="h-10 mx-auto" src="@/assets/5.png" alt="" />
          <h1
            class="mt-5 text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
          >
            <span class="block text-white">Lunch.</span>
          </h1>
        </div>
      </div>
    </div>
    <!-- Header -->
    <!-- s -->
    <div class="relative bg-sg shadow-md">
      <div
        class="h-56 bg-indigo-600 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2"
      >
        <img
          class="w-full h-full object-cover"
          :src="lunch.Intro_Image"
          alt="Support team"
        />
      </div>
      <div
        class="relative max-w-7xl mx-auto px-4 py-8 sm:py-12 sm:px-6 lg:py-16"
      >
        <div
          class="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-1/2 lg:pl-10"
        >
          <h2 class="mt-6 text-3xl font-extrabold text-white sm:text-4xl">
            {{ lunch.Intro_Title }}
          </h2>
          <p class="mt-6 text-lg text-white">
            {{ lunch.Intro_Paragraph }}
          </p>
        </div>
      </div>
    </div>
    <!-- s -->

    <!-- GALLAERY SECTIION -->
    <div class="bg-white">
      <div class="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <div>
          <h2 id="products-heading" class="sr-only">Products</h2>
          <div class="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
            <div class="pb-16 xl:flex xl:items-center xl:justify-between">
              <div>
                <h1
                  class="text-3xl font-extrabold text-white sm:text-4xl sm:tracking-tight mb-5"
                >
                  <span class="text-gray-900">Lunch served </span>
                  <span class="text-pb">{{ lunch.Serving_Time }}</span>
                </h1>
              </div>
              <router-link
                to="/menu"
                class="rl flex items-center justify-center px-4 py-3 border-2 border-sg text-base font-medium text-black hover:bg-pb hover:bg-opacity-50 mb-5"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                View Menu
              </router-link>
            </div>
          </div>
          <div
            class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8"
          >
            <div
              v-for="(product, keys) in gallerys"
              :key="product.image"
              class="group"
            >
              <div
                class="w-full aspect-w-1 aspect-h-1 overflow-hidden sm:aspect-w-2 sm:aspect-h-3 shadow-md"
              >
                <img
                  :src="product.Image"
                  class="w-full h-full object-center object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      gallerys: [],
      lunch: {
        Header_Image: "",
        Intro_Image: "",
        Intro_Title: "",
        Intro_Paragraph: "",
        Serving_Time: "",
      },
    };
  },

  methods: {
    getLunch() {
      let __this = this;
      axios
        .get(
          "https://api.storyblok.com/v2/cdn/stories/lunch?cv=1657612186&token=e90vmTfGJpbIf0qqX2mihwtt&version=published"
        )
        .then((response) => {
          let content = response.data["story"]["content"];
          __this.lunch = {
            Header_Image: content["Header_Image"]["filename"],
            Intro_Image: content["Intro_Image"]["filename"],
            Intro_Title: content["Intro_Title"],
            Intro_Paragraph: content["Intro_Paragraph"],
            Serving_Time: content["Serving_Time"],
          };
          for (const keys in content["Gallery"]) {
            __this.gallerys.push({
              Image: content["Gallery"][keys]["Image"]["filename"],
            });
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.getLunch();
  },
};
</script>

<style></style>
